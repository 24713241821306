import React from "react"
import ReactDynamicImport from "react-dynamic-import"
import { detectMob } from "@src/Helper"
import SEO from "@components/seo"

const loader = () => {
  if (!detectMob()) {
    return import("@components/Pricing/NewPricing")
  } else {
    return import("@components/Pricing/PricingMobile")
  }
}

const Pricing = ReactDynamicImport({ loader })

const PricingPage = () => {
  return (
    <>
      <SEO title="Pricing" />
      <Pricing />
    </>
  )
}

export default PricingPage
